@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 400;
    src: local('Inter UI'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Regular.d436ae63.woff2) format('woff2'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Regular.03935077.woff) format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 400;
    src: url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Italic.c9f18e10.woff2) format('woff2'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Italic.f831942f.woff) format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 500;
    src: url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Medium.188de38a.woff2) format('woff2'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Medium.840d4ba9.woff) format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 500;
    src: url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-MediumItalic.1dff341b.woff2) format('woff2'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-MediumItalic.f3dc4fab.woff) format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 700;
    src: url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Bold.b28d4929.woff2) format('woff2'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-Bold.22438e97.woff) format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 700;
    src: url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-BoldItalic.fab8553b.woff2) format('woff2'),
    url(https://secure-static.fattureincloud.it/21363c30668c50ef2aa22a6745e9430d/static/media/Inter-UI-BoldItalic.11607964.woff) format('woff');
}

body {
    font-family: 'Inter UI', sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ajax-link {
  cursor: pointer;
}

#root {
  height: 100%;
}

.react-datepicker__aria-live {
    display: none;
}

h4.modal-title {
  margin: 0px;
}

.fic-modal {
  position: fixed;
  z-index: 1050;
  width: 560px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  outline: none;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.26);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 0;
  /*top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  */
  top: 10%;
  left: 0;
  right: 0;
  bottom: auto;
  margin: auto;
  z-index: 1050;
  pointer-events: all;
  color: #555;
}

.fic-modal {
  top: -10%;
  transition: opacity 0.3s linear, top 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear 0.3s, opacity 0.3s linear, top 0.3s ease-out;
  z-index: 1050;
}

.fic-modal-overlay.fic-modal-visible .fic-modal {
  top: 10%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.fic-modal-overlay.fic-modal-hidden .fic-modal {
  top: 30%;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s;
}

.fic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.48);
  transition: opacity 0.3s linear, top 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear 0.3s, opacity 0.3s linear, top 0.3s ease-out;
  z-index: 1050;
  pointer-events: all;
}

.fic-modal-overlay.fic-modal-visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.fic-modal-overlay.fic-modal-hidden, .fic-modal-overlay.fic-modal-closed {
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s;
}

.fic-modal .modal-header {
  padding: 15px;
}

.fic-modal .modal-body {
  font-size: 14px;
  z-index: 1051;
  padding-bottom: 20px;
  overflow-y: visible;
}

.fic-modal.scrollable .modal-body {
  overflow: auto;
}

.fic-modal .modal-footer {
  text-align: center;
  z-index: 1051;
}

.fic-modal .modal-footer .footer-item.left {
  float: left;
}

.fic-modal .modal-footer .footer-item.left + .footer-item.left {
  margin-left: 8px;
}

.fic-modal .modal-footer .footer-item.center {
  float: none;
}

.fic-modal .modal-footer .footer-item.center + .footer-item.center {
  margin-left: 8px;
}

.fic-modal .modal-footer .footer-item.right {
  float: right;
}

.fic-modal .modal-footer .footer-item.right + .footer-item.right {
  margin-right: 8px;
}

.fic-modal .nav-tabs > li, .nav-pills > li {
  float: right;
}

.fic-modal .modal-body .small-gray-message {
  font-size: 12px;
  color: #888888;
}

/* INIZIO aggiunte css per possibile mancanza di bootstrap */
.modal-body {
  position: relative;
  max-height: 400px;
  padding: 15px;
  overflow-y: auto;
}
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  *zoom: 1;
  box-shadow: inset 0 1px 0 #ffffff;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  line-height: 0;
  content: "";
}
.modal-footer:after {
  clear: both;
}

.modal-footer-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* FINE aggiunte css per possibile mancanza di bootstrap */


.btn-flat.fic-text-button {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}

.btn-flat.fic-text-button.small {
  border-radius: 4px;
  padding: 3px 6px;
}

.btn-flat.fic-text-button.medium {
  border-radius: 4px;
  padding: 5px 9px;
}

.btn-flat.fic-text-button.large {
  border-radius: 4px;
  padding: 7px 14px;
  height: 14px;
  max-height: 14px;
}

.btn-disabled {
  opacity: 0.6;
  pointer-events: none;
}

